import React from 'react';
import { GetNewsBlockEN } from '../../content/pages/home/newsBlock/en/GetNewsBlock';
import { GetNewsBlockFR } from '../../content/pages/home/newsBlock/fr/GetNewsBlock';
import './news.scss';

const News = ({lang}) => {
    const content = lang === 'fr' ? GetNewsBlockFR() : GetNewsBlockEN()
    return (
        <div className="news-block">
            <h3
            >{content.newsTitle}</h3>
            <p>{content.newsDate}</p>
            {content.newsContent.map((e,i) => (
                <p key={i}>{e}</p>
            ))}
        </div>
    );
};

export default News;
import {useStaticQuery, graphql} from 'gatsby';


export const GetHomeSeoFR = () =>  {
	
	const content = useStaticQuery(graphql`
	query HomeQueryFR {
		allMarkdownRemark(
		  filter: {frontmatter: {lang: {eq: "fr"}}, fields: {collection: {eq: "homeSeoBlock"}}}
		) {
		  edges {
			node {
			  frontmatter {
				pageDescription
				pageTitle
				lang
			  }
			}
		  }
		}
	  }
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};
import {useStaticQuery, graphql} from 'gatsby';


export const GetMediaFR = () =>  {
	
	const content = useStaticQuery(graphql`
	query homeMediaQueryFR {
		allMarkdownRemark(
		  filter: {frontmatter: {lang: {eq: "fr"}}, fields: {collection: {eq: "homeMediaBlock"}}}
		) {
		  edges {
			node {
			  frontmatter {
                lang
                MediaTitle
                medias
                {
                    url
                    name
                    title
                    content
                    image {
                        childImageSharp{
                            fixed{
                                src
                            }
                        }
                    }
                }
			  }
			}
		  }
		}
	  }
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};
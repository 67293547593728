import {useStaticQuery, graphql} from 'gatsby';

const GetAllArticlesFR = () => {
	const content = useStaticQuery(graphql`
	query AllArticle {
		allMarkdownRemark(
            filter: { fields: {collection: {eq: "BlogPost"}}}
        ) {
		  edges {
			node {
			  frontmatter {
          id
          author
          fr {
            articleTitle
            subtitle
            date
            section {
              subtitle
              img
              p
            }
          }
          en {
            date
            articleTitle
            subtitle
            section {
              subtitle
              img
              p
            }
          }
				}
			 }
		  }
		 }
	  }
`);
    return content.allMarkdownRemark.edges;
};

export default GetAllArticlesFR;
import {useStaticQuery, graphql} from 'gatsby';


export const GetDonateBlockEN = () =>  {
	
	const content = useStaticQuery(graphql`
	query DonateBlockEN {
		allMarkdownRemark(
		  filter: {frontmatter: {lang: {eq: "en"}}, fields: {collection: {eq: "HomeDonateBlock"}}}
		) {
		  edges {
			node {
			  frontmatter {
				lang
				donateTitle
				donateContent
				donateButton
			  }
			}
		  }
		}
	  }
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};
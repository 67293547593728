import React from 'react';
import { GetOurMissionEN } from '../../content/pages/home/ourMissionBlock/en/GetOurMissionEN';
import { GetOurMissionFR } from '../../content/pages/home/ourMissionBlock/fr/GetOurMissionFR';
import {Link} from 'gatsby';
import './ourMission.scss';

const OurMission = ({lang}) => {
    const content = lang === 'fr' ? GetOurMissionFR() : GetOurMissionEN()
    return (
        <div className="our-mission-block" >
            <h3>{content.ourMissionTitle}</h3>
            <p>{content.ourMissionContent}</p>
            <p>{content.ourMissionBoldContent}</p>
            <button><Link to="/foundation">{content.ourMissionMore}</Link></button>
        </div>
    );
};

export default OurMission;
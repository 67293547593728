/******************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Page d'accueil.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React, {useContext} from 'react';
import Layout from '../components/template/layout';
import { LangContext } from '../components/context/langContext';
import {GetHomeSeoFR} from '../content/pages/home/seoBlock/fr/GetHomeSeoFR'
import {GetHomeSeoEN} from '../content/pages/home/seoBlock/en/GetHomeSeoEN'
import MainTitle from '../components/home/MainTitle';
import OurMission from '../components/home/OurMission';
import News from '../components/home/News';
import Donate from '../components/home/Donate';
import ArticleTeasers from '../components/articles/articles-teaser';
import NewsLetter from '../components/home/newsLetter';
import Media from '../components/home/Media';

const IndexPage = () => {
	const ctx = useContext(LangContext)
	const content = ctx.lang === 'fr' ? GetHomeSeoFR() : GetHomeSeoEN()
	return (
			<Layout 
				title={content.pageTitle}	
				lang={ctx.lang}
				description={content.pageDescription}
				page="home"
			>
					<MainTitle 
						lang={ctx.lang}
					/>
					<div className="mission-news-block">
						<OurMission 
							lang={ctx.lang}
						/>
						<div className="right-row">
							<News 
								lang={ctx.lang}
							/>
							{/*
							<NewsLetter 
								lang={ctx.lang}
							/>
							*/}
						</div>
					</div>
					{ctx.lang === 'fr' &&
						<Media
							lang={ctx.lang}
						/>
					}
					<Donate
						lang={ctx.lang}
					/>
					<ArticleTeasers
						lang={ctx.lang}
						nb={4}
				/>
			</Layout>
	);
};

export default IndexPage;

import React from 'react';
import { GetHeaderEN } from '../../content/pages/home/headerBlock/en/getHeaderEN';
import { GetHeaderFR } from '../../content/pages/home/headerBlock/fr/getHeaderFR';

import './title.scss';
const MainTitle = (props) => {
    const content = props.lang === 'fr' ? GetHeaderFR() : GetHeaderEN()
    return (
        <div className="title"> 
            <h1   
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease">{content.HeaderTitle}</h1>
            <p>{content.HeaderDescription}</p>
        </div>
    );
};

export default MainTitle;
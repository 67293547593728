import React from 'react';
import { GetMediaFR } from '../../content/pages/home/mediaBlock/fr/getMedia';
import './media.scss';
import {Link} from 'gatsby';
import Img from 'gatsby-image';

const Media = ({lang}) => {
    const content = GetMediaFR()
    return (
        <div className="media-background content-container">
            <h3
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
                {content.MediaTitle}
            </h3>
            <div className="media-card-container">
                {content.medias.map((e,i)=>(
                    <div className="media-card">
                        <Link to={e.url} >
                            <Img fixed={e.image.childImageSharp.fixed} />
                            <div>
                                <p className="media-name">{e.name}</p>
                                <h5>{e.title}</h5>
                                <p>{e.content}</p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Media;
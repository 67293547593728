import React from 'react';
import { GetDonateBlockEN } from '../../content/pages/home/donateBlock/en/GetDonateBlock';
import { GetDonateBlockFR } from '../../content/pages/home/donateBlock/fr/GetDonateBlock';
import './donate.scss';
import {Link} from 'gatsby';

const Donate = ({lang}) => {
    const content = lang === 'fr' ? GetDonateBlockFR() : GetDonateBlockEN()
    return (
        <section className="donate-cto">
            <div>
                <p className="donate-title">{content.donateTitle}</p>
                    {content.donateContent.map((e,i)=>(
                        <p className={'donate-' + i} key={i}>{e}</p>
                    ))}
            </div>
            <button className="blue-button"><Link to="/donate">{content.donateButton}</Link></button>
        </section>
    );
};

export default Donate;
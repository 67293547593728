import React from 'react';
import {Link} from 'gatsby';
import './article-card.scss';

const ArticleCard = ({article, lang}) => {
    return (
        <Link className="article-card" to={`/${article.node.frontmatter.id}`}>
            <p>{article.node.frontmatter.author}</p>
            <p>{article.node.frontmatter[`${lang}`].date}</p>
            <h5>{article.node.frontmatter[`${lang}`].articleTitle}</h5>
            <p>{article.node.frontmatter[`${lang}`].section[0].p.split(' ').slice(0, 20).join(' ') + '...'}</p>
         </Link>
    );
};

export default ArticleCard;
import React from 'react';
import './article-teasers.scss';
import GetAllArticles from '../../content/blog/GetAllArticles';
import ArticleCard from './article-card';
import './article-teasers.scss';

const ArticleTeasers = ({lang, nb}) => {
	let content
	if(nb){
		content = GetAllArticles().slice(0,nb);
	} else {
		content = GetAllArticles()
	}
	const title = {
		fr: "DERNIERS ARTICLES DU BLOG",
		en: "LAST BLOG POSTS"
	}
	return (
		<div className="article-teaser-box" >
			<h3
			  data-sal="slide-up"
			  data-sal-delay="300"
			  data-sal-easing="ease"
			> {title[lang]} </h3>
			<div className="article-teaser">
					{content && lang && content.map((e,i)=> (
							e.node.frontmatter[lang].section[0].p.length > 0 &&
							<ArticleCard 
							article={e}
							lang={lang}
						/>							
					))}
			</div>
		</div>
	)
};

export default ArticleTeasers;

